

.svg-logo {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
    color: var(--on-secondary-color);
}

.footer {
    display: flex;
    background-color: var(--secondary-color);
    color: var(--on-secondary-color);
    padding: 1.5rem 0rem;
    justify-content: center;
    align-items: center;
}

.footer-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
}

.footer-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-footer > :nth-child(2) {
    margin-top: 1rem;
}

.footer-content {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-content > div:nth-child(2) {
    margin-top: 1.5rem;
}

.contact-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.links-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-logo {
    display: flex;
    align-items: center;
}

.links-footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-footer > div{
    padding-top: 1.5rem;
}

.section-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-links > div {
    padding-top: 1.5rem;
}

#right {
    text-align: center;
    font-size: 0.7rem;
}

@media (min-width: 900px) {
    #right {
        margin: 0;
    }

    .footer {
        height: 25%;
        padding: 0;
    }

    .footer-content {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: baseline;
        margin: 0;
        font-size: 0.7rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .footer-container {
        width: 100%;
        margin: 0 120px;
    }

    .contact-footer, .footer-container, .links-footer, .links-footer-content, .section-links {
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;
    }

    .links-footer-content {
        flex-direction: column;
    }

    .footer-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--on-secondary-color);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .links-footer {
        margin-left: 10rem;
        margin-top: 0rem;
    }

    .section-links {
        display: grid;
        grid-template-columns: 200px auto;
        grid-template-rows: auto;
        // flex-direction: row;
        // flex-wrap: wrap;
        // width: 200px;
    }

    .section-links > div {
        margin-right: 20px;
    }

    .footer-content > div:nth-child(2) {
        margin-top: 0rem;
    }

    h2 {
        font-size: 1rem;
    }

    .contact-footer > div{
        padding-top: 0.8rem;
    }


    .section-links > div {
        padding-top: 0.8rem;
    }
}