.swiper {
    width: 90%;
    height: 90%;
    margin: 5vh 5vw;
  }

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }


  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
  
.testt {
    background-color: rgba(0, 0, 0, 0.65);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 14;
    width: 100%;
    height: 100%;
}

.swiper-pagination-bullet {
    background-color:  var(--primary-color) !important;
}

.swiper-button-next,.swiper-button-prev {
    color:  var(--primary-color) !important;
}

.cross-swiper {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
}