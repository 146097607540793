.profil-container {
    display: flex;
    margin: 24px 12px;
	margin-top: 6rem;
}


.profil-picture > img {
    object-fit: cover;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.profil-infos {
    width: 100%;
    height: 100px;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 7px 10px;
    gap: 1rem;
}

.profil-info {
    .profil-details {
        margin-top: 0.25rem;
    }
}

.profil-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profil-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .line {
        margin-top: 2rem;
        border-bottom: 2px solid #bccbb072;
    }
}

.profil-title {
    font-weight: bold;
}

.btn-download-pictures {
    background-color: var(--primary-color);
    color: white;
    height: 3.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem;
    gap: 1rem;
    border-radius: 2rem;
    font-weight: bold;

    cursor: pointer;

    img {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.profil-gallery {
    width: 100%;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    column-gap: 3px;
    row-gap: 3px;
    z-index: 1;

    div {
        padding-top: 100%;
        position: relative;
    }

    div > img {
        width: 100%;
        height: 100%;
        max-height: 340px;
        max-width: 340px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;

    }
}


@media (min-width: 900px) {
    .profil-container {
        margin-left: 120px;
        margin-right: 120px;
    }

    .profil-infos {
        padding: 10px 0px;
    }
}