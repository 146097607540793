.text-content-container {
    width: 80%;
    background-color: var(--on-secondary-color);
    margin: auto;
    border-radius: 1rem;
    margin-top: 7rem;
    margin-bottom: 4rem;
    padding: 2rem;

    h1 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }
    p {
        font-size: 1.1rem;
    }
}