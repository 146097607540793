.flash-message {
    z-index: 10;
    position: fixed;
    top: 3.5%;
    left: 2%;
    width: 96%;
    text-align: center;
    height: 3.5rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    p {
        width: 80%;
        font-weight: bold;
    }
}