.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: -webkit-sticky;
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--background-color);
    padding: 24px 12px;
    z-index: 5;
}

.image-container {
    width: 250px;
    height: 40px;
    cursor: pointer;
}

img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.navigation-container {
    display: none;
}


.hamburger-container {
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.side-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    min-height: 100%;
    background-color: var(--secondary-color);
    color: var(--on-secondary-color);
    align-items: center;
    z-index: 1000000000;
}

.side-menu > ul {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 30px;
}

.side-menu > ul > a > li, .side-menu > ul > a {
    text-transform: none;
    font-weight: bold;
    color: var(--on-secondary-color);
    text-decoration: none;
}

.cross {
    align-self: end;
    margin: 16px;
}

.line {
    border-bottom: 1px solid #bccbb072;
    width: 80%;
}

@media (min-width: 900px) {
    .header-container {
        padding: 24px 120px;
    }
    .image-container {
        min-width: 300px;
        min-height: auto;
    }

    .hamburger-container {
        display: none;
    }
    .navigation-container {
        display: block;
        width: 300px;
        height: auto;
        color: var(--on-secondary-color);
        font-weight: bold;
    }

    ul {
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
        background-color: var(--secondary-color);
        border-radius: 32px;
    }

    .navigation-container {
        a {
            li {
                font-size: 0.9rem;
                list-style-type: none;
                text-transform: uppercase;
            }
            color: var(--on-secondary-color);
            text-decoration: none;
        }
    }
}