:root {
  --primary-color: #356A22;
  --secondary-color: #55624C;
  --on-secondary-color: #BCCBB0;
  --background-color: #FDFDF6;
  --secondary-container : #D8E7CB;
  --on-secondary-container: #131F0D;
}

* {
  box-sizing: border-box;
  margin : 0;
  padding: 0;
}

html {
  font-size: 100%;
}

body {
  font-family: "Georgia";
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}