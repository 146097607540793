a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}

.underline {
    font-size: 1rem;
    width: 1rem;
	text-align: center;
    padding-bottom: 3px;
    color: var(--on-secondary-color);
	background-color: transparent;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--on-secondary-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.left-main-part {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.underline:nth-child(n+2) {
    margin-left: 1ch;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
	gap: 30px;
	margin: 40px 12px;
	margin-top: 6rem;
}

.explain {
	align-self: flex-start;
	margin: 0;
	font-weight: bold;
	font-size: 1.2rem;
	text-align: center;
	width: 26ch;
}

.main-image-container {
    width: 39px;
    height: 41px;
    cursor: pointer;
	margin: 0;
}

.main-image-container > img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.hero-image {
	// size for large screen
    // width: 305px;
    // height: 373px;
	// size for mobile
	width: 100%;
	height: auto;
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.hero-image > img {
	width: 100%;
	height: 100%;
	object-fit: cover;


	// radius for large screen
	// border-radius: 50px 140px 50px 130px;

}

.main-input {
    display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin-top: 1rem;
	position: relative;
}

.container-input {
	background-color: var(--secondary-color);;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 32px;
	padding: 10px 35px;
}

.main > p {
	font-size: 19px;
	font-weight: bold;
}

.info-code {
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
}

.container-info-code {
	display: flex;
	align-items: center;
	flex-direction: row;
	position: relative;
	left: -0.5rem;
	font-size: 0.7rem;
	font-style: italic;
}

.content-main-input {
	display: flex;
}

@media (min-width: 900px) {
	#root {
		width: 100vw;
		height: 100vh;
	}
    .main-container {
		flex-direction: row-reverse;
		justify-content: normal;
		align-items: center;
		margin-left: 120px;
		margin-right: 120px;
		width: auto;
		height: 75%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-top: 7rem;
		.left-main-part {
			width: 70%;
			.content-left-part {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
			}
		}

		.explain {
			font-size: 2.5vw;
			text-align: center;
			width: 26ch;
		}

		.hero-image {
			width: 30%;
			height: 100%;
			padding-bottom: 2rem;
			img {
				border-radius: 15% 40% 15% 40%;
			}
		}

		.main-input {
			margin-top: 8vh;
			input {
				font-size: 1.5rem;
				width: 1.5rem;
			}
		}
	}

	.main-image-container {
		width: 50px;
		height: 50px;
		margin-left: 0.5rem;
	}

	.info-code {
		width: 1.2rem;
		height: 1.2rem;
	}

	.container-info-code {
		font-size: 1.1vw;
	}
}